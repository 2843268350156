/* Scrollbar Styling */

*::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #111827;
}

*::-webkit-scrollbar
{
  width: 10px;
  background-color: #111827;
}

*::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #F5F5F5;
}

/* Horizontal scrollbar styling */

/* div::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

div::-webkit-scrollbar
{
  width: 10px;
  height: 10px;
  background-color: transparent;
}

div::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #F5F5F5;
} */